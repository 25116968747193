.scrollPdf + .scrollPdf {
  margin-top: 20px;
}

.pdfFullModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .6);
  z-index: 5;

  .pdfWrap {
    position: relative;
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    max-width: fit-content;

    .pdfFull {
      :global {
        .react-pdf__Page__canvas {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }
}

.pdfImageWrap {
  position: relative;
  width: fit-content;
}

.buttons {
  position: fixed;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  button + button {
    margin-left: 20px;
  }
}

.close {
  position: fixed;
  top: 10px;
  right: 10px;
  background: #ffffff;
  border: none;
  font-size: 20px;
  border-radius: 5px;
  z-index: 10;
  cursor: pointer;
}

.loadingImage {
  position: relative;
  min-height: 300px;
  height: 100%;
  width: 100%;

  canvas {
    min-height: 300px !important;
    height: 100% !important;
  }

  :global {
    .ant-skeleton-element {
      width: 100% !important;

      .ant-skeleton-image {
        width: 100% !important;
        min-height: 300px;
        height: 100%;
      }
    }

    .ant-spin-dot-item {
      background: #000000;
    }
  }
}
