.editImage {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin: 0 auto;
  padding: 5px;

  .zoomButtons {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .zoomSlider {
    width: 300px;
  }

  .rotateButtons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}