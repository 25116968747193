.container {
  position: absolute;
  left: 0;
  top: 100px;
  bottom: 0;
  width: 0;

  .documentDetailMenu {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    border: 1px solid #1890ff;
    border-left: 0;
    border-bottom: 0;
    border-top-right-radius: 4px;
    background: #40a9ff;
    overflow: hidden;

    z-index: 1;
  }

  &.containerActive {
    width: 300px;

    .documentDetailMenu {
      border-color: #40a9ff;
      border-width: 4px;
      width: 301px;
      background: #ffffff;
      overflow-y: auto;
    }

    .buttons {
      left: calc(100% - 20px);
    }
  }

  .buttons {
    position: absolute;
    left: -64px;
    top: 100px;
    display: flex;
    transform: rotate(90deg);

    z-index: 10;
  }

  .editButton {
    border: 1px solid #1890ff;
    border-bottom-color: #40a9ff;
    border-radius: 4px 4px 0 0;
    background: #40a9ff;
    color: #ffffff;
    outline: none;
    cursor: pointer;
  }

  .editButton + .editButton {
    margin-left: 6px;
  }
}


