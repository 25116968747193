.container {
  padding: 10px;
}

.centered {
  .meta {
    justify-content: center;
  }
}

.meta {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;

  img {
    max-width: 40%;
  }

  h2 {
    font-size: 30px;
    color: rgba(0, 0, 0, .7)
  }

  .phones {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 650px) {
  .meta {
    flex-wrap: wrap;
    gap: 0;

    img {
      max-width: 80%;
      margin-right: 20px;
    }

    h2 {
      font-size: 16px;
    }

    .phones {
      width: 100%;
      justify-content: center;
      flex-direction: row;
    }
  }

}

@media (max-width: 450px) {
  img {
    margin-right: 0;
  }
}