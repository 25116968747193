.nav {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 50px;
  background: #ffffff;
  z-index: 10;
}

.nav > * {
  margin: 5px;
}

@media (max-width: 321px) {
  .nav {
    :global {
      .ant-btn {
        padding: 2px 10px !important;
        font-size: 13px !important;
      }
    }
  }
}
