.undraggableImage {
  width: 16.67%;
  height: 50px;
}

.draggable {
  cursor: move;

  button {
    outline: none;
    border: none;
    background: none;
    pointer-events: none;
  }
}

.pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);
  color: #40a9ff;
}

.image {
  width: 35px !important;
  height: 35px !important;
  object-fit: cover;
  pointer-events: none;

  :global {
    .ant-skeleton-element {
      width: 50%;
      height: 50%;
    }
  }
}

.pdf, .image {
  &.activeImage {
    border: 3px dashed #40a9ff;
  }
}