.loadingImage {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  :global {
    .ant-skeleton-element {
      width: 300px;
    }

    .ant-skeleton-image {
      width: 100%;
      height: 300px;
    }

    .ant-spin-dot-item {
      background: #000000;
    }
  }
}