.gallery {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-height: 76.95vh;
  overflow: auto;

  .galleryItem {
    display: flex;

    .galleryPdf {
      margin: auto;
      height: 100%;
      width: 100%;
    }

    .galleryImage {
      margin: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .flipButton {
    width: 100%;
    height: 4vh;
    border: none;
    background: rgba(0, 0, 0, .1);
    border-radius: 1px;
    cursor: pointer;

    &:disabled {
      opacity: .3;
      cursor: default;
    }
  }
}