.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .detailsNavigation {
    width: fit-content;
    position: static;
    height: 40px;
  }
}

.patientInfo {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex: 2;
}