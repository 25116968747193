.images {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.imageContainer {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 200px;
  margin-bottom: 10px;

  .imageWrap {
    width: 200px;
    height: 200px;
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);
    margin-top: 3px;

    .pdf {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
      padding-top: 20%;
      color: #40a9ff;

      span {
        font-weight: 500;
        font-size: 20px;
      }

      svg {
        font-size: 50px;
      }
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .buttons {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;

    button + button {
      margin-left: 10px;
    }
  }
}

@media (max-width: 500px) {
  .imageContainer {
    width: 180px;
    height: 180px;

    .imageWrap {
      width: 180px;
      height: 180px;
    }
  }
}

@media (max-width: 400px) {
  .imageContainer {
    width: 230px;
    height: 230px;

    .imageWrap {
      width: 230px;
      height: 230px;
    }
  }
}



