.progress {
  position: fixed;
  bottom: 50px;
  right: 50px;
  border-radius: 50%;
  background: #ffffff;
  z-index: 30;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0s linear, 2s;
}

.waitingIcon {
  font-size: 2em;
  color: #ffa500;
}

@media (max-width: 768px) {
  .progress {
    bottom: 20px;
    right: 20px;
  }
}