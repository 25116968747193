.typeSelect {
  margin: 10px;
  width: calc(100% - 20px);
}

.collapse {
  background: #ffffff;
}

.panel {
  :global {
    .ant-collapse-content-box {
      padding-bottom: 0;
      padding-right: 0;
    }
  }
}

.collectionDropZone {
  user-select: none;
  display: flex;
  flex-wrap: wrap;
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 52px;
  background: rgba(0,0,0, .3);

  z-index: 10;
}
