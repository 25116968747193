.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  padding: 0;
}

.item {
  width: 500px;
  padding: 12px 0;
  border-bottom: 1px solid #808080;

  &:last-child {
    border-bottom: none;
  }

  .meta {
    margin-bottom: 10px;

    b {
      font-size: 16px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .slider {
      width: 100px;
    }

    .rotateButtons {
      button + button {
        margin-left: 10px;
      }
    }
  }

  .image {
    width: 100%;
    cursor: pointer;
  }

  .scale {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .3);
    display: flex;
    overflow: auto;
    z-index: 10;

    .image {
      margin: auto;
      width: 500px;
      cursor: default;
    }

    .pdf {
      margin: auto;
      width: 500px;
      cursor: default;
    }
  }

  .pdf {
    cursor: pointer;

    :global {
      .react-pdf__Page__canvas {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}

.sliderScale {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  .slider {
    width: 400px !important;
  }
}

@media (max-width: 768px) {
  .item {
    width: 100%;
  }
}