.buttons {
  position: sticky;
  top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  z-index: 10;
  button {
    min-width: 100px;
  }
}

