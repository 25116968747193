.moveActions {
  position: fixed;
  top: 0;
  left: 0;

  width: 301px;
  border-right: 4px solid #40a9ff;
  display: flex;
  background: #ffffff;

  .moveActionsDropZone {
    width: 50%;
    height: 104px;

    border-right: 4px solid #40a9ff;

    &:last-child {
      border-right: none;
    }

    &:hover {
      .moveActionsIcon {
        span {
          font-size: 50px;
        }
      }
    }
  }

  .moveActionsIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    span {
      font-size: 45px;
      color: #40a9ff;
    }
  }
}