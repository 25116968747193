.galleryNavigation {
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px auto 0;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  overflow-x: auto;
  height: 40px;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    height: 0;
  }
}

.loadingDocument {
  :global {
    .ant-skeleton-image {
      height: 25px;
      width: 25px;
    }

    .ant-skeleton-image-svg {
      height: 15px;
      width: 15px;
    }
  }
}

.navigationItem {
  &.navigationItemActive {
    border: 3px dashed #40a9ff;

    .button {
      cursor: auto;
    }
  }

  .button {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;

    .pdf {
      width: 25px;
      height: 25px;
      box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);
      color: #40a9ff;;
    }

    .navigationImage {
      width: 25px;
      height: 25px;
      object-fit: cover;
    }

    :global {
      .react-pdf__Document {
        background: rgba(#8c8c8c, .3);
        width: 25px !important;
        height: 25px !important;
        object-fit: cover;
      }
    }
  }
}