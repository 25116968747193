html, body {
  overflow: auto;
}

body {
  padding: 0 10px;
  // background: #faf9f7
}

ul, ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.title {
  text-align: center;
  margin-bottom: 0 !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

.ant-table table {
  font-size: 12px;
}

.ant-checkbox-wrapper {
  display: flex;
  margin-bottom: 10px;
}

.ant-checkbox-group {
  display: block;
}

.ant-form-item-label {
  padding-bottom: 0 !important;
}

.react-pdf__Page__textContent {
  display: none !important;
}

// necessary for iphone, min font-size 16px to avoid zoom
.ant-form-item-control-input-content input, .ant-input, .ant-select-selection-item, .ant-picker .ant-picker-input input {
  font-size: 16px !important;
}
