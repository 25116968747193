.list {
  .statusIcon {
    font-size: 25px;
  }

  .pdf {
    :global {
      .react-pdf__Page__canvas {
        width: 120px !important;
        height: auto !important;
      }
    }

  }

  .image {
    width: 120px;
  }
}

@media (max-width: 768px) {
  .pdf {
    :global {
      .react-pdf__Page__canvas {
        width: 70px !important;
        height: auto !important;
      }
    }
  }

  .list {
    :global {
      .ant-typography {
        font-size: 12px !important;
      }
    }

    .image {
      width: 70px;
    }
  }
}

